.regholder {
  padding: 19px;
  background-color: rgb(243, 198, 55);
  border-radius: 7px;
}

.MuiOutlinedInput-input {
  background-color: white !important;
  color: black !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  background-color: white !important;
}

.subAlert {
  width: 100%;
  height: 3rem;
  background-color: rgb(196, 0, 0);
  color: white;
  font-size: 13px;
  font-weight: bolder;
  text-align: center;
  padding-top: 13px;
  position: fixed;
  top: 0;
  z-index: 9;
}

.SubsMargin {
  margin-top: 4rem;
}

@media (max-width: 400px) {
  .SubsMargin {
    margin-top: 1rem;
  }

  .subAlert {
    height: 2rem;
    color: white;
    font-size: 10px;
    text-align: center;
    padding-top: 10px;

  }



}