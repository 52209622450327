.regholder {
  padding: 1vh 2vw 3vh 2vw;
  background-color: #f3d715;
  border-radius: 50px;
}
.btn {
  background-color: black !important;
  color: #fff !important;
  font-weight: 600 !important;
  height: 7vh !important;
  border-radius: 20px !important;
}
.btn:hover {
  background-color: #e49001 !important;
  color: black !important;
}
.removeBtn {
  background-color: rgb(196, 58, 58);
  color: #fff;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
}
.removeBtn:hover {
  background-color: rgb(131, 10, 10);
}
.tableBtn {
  background-color: rgb(251, 191, 5);
  color: #000;
  border: 0;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  padding: 0.5rem 1rem;
}
.tableBtn:hover {
  background-color: rgb(205, 161, 32);
}
